import React from "react";
import chatLogoDoc from "/company/chat_logo_doc@2x.png";

type Props = {
  onClick: () => void;
};

const AnalysisChatIcon: React.FC<Partial<Props>> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="block md:hidden fixed right-4 bottom-20 rounded-full shadow-lg z-10"
    >
      <img className="w-16 rounded-full" src={chatLogoDoc} alt="logo_icon" />
    </button>
  );
};

export default AnalysisChatIcon;
