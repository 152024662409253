import React from "react";
import chatLogoDoc from "/company/chat_logo_doc@2x.png";
import { MobileViewType } from "../../../../types";

type ChatIconProps = {
  handleMobileViewButton: (type: MobileViewType) => void;
};
const ChatIcon: React.FC<ChatIconProps> = ({ handleMobileViewButton }) => {
  return (
    <button
      onClick={() => handleMobileViewButton("CHAT")}
      className="fixed right-5 bottom-20 rounded-full shadow-lg"
    >
      <img className="w-16 rounded-full" src={chatLogoDoc} alt="logo_icon" />
    </button>
  );
};

export default ChatIcon;
